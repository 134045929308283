<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import appConfig from "@/app.config";
import blogEditSvc from "@/services/blog-edit";

/**
 * Customers component
 */
export default {
  page: {
    title: "Blog List (Request For Edit)",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Blog List (Request For Edit)",
      role:
        localStorage.getItem("userInfo") !== null
          ? JSON.parse(localStorage.getItem("userInfo")).role
          : "user",
      items: [
        {
          text: "Blog",
          href: "#",
        },
        {
          text: "Blog List (Request For Edit)",
          active: true,
        },
      ],
      columns: [
        {
          field: "uuid",
          hidden: true,
        },
        {
          field: "statusText",
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Status",
          field: "statusCode",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Status",
            filterDropdownItems: [
              { value: "draft", text: "Editing Draft" },
              { value: "pending_verify", text: "Pending Verify" },
              { value: "editing", text: "Editing" },
              { value: "rejected", text: "Rejected" },
            ],
            trigger: "enter",
          },
        },
        {
          label: "Created Date",
          field: "createdAt",
          type: "Date",
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          type: "Date",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      blogEditSvc.getAll().then((resp) => {
        if (resp.data.data === null) {
          return;
        } else {
          this.rows = resp.data.data.filter((blog) => {
            if (blog.statusCode !== "publish_draft") {
              return blog;
            }
          });
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      v-if="props.formattedRow.statusCode === 'pending_verify'"
                      size="sm"
                      :variant="role !== 'user' ? 'warning' : 'primary'"
                      router-link
                      :to="`manage-edit?uuid=${props.formattedRow.uuid}`"
                    >
                      <i
                        :class="`mdi mdi-${
                          role !== 'user' ? 'file-check-outline' : 'eye'
                        } font-size-16 align-middle`"
                      ></i>
                      {{ role !== "user" ? "Verify" : "View" }}
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="success"
                      router-link
                      :to="`manage-edit?uuid=${props.formattedRow.uuid}`"
                    >
                      <i class="mdi mdi-pencil font-size-16 align-middle"></i>
                      Edit
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field == 'statusCode'">
                    <b-badge
                      v-if="
                        [
                          'created',
                          'pending_verify',
                          'editing',
                          'draft',
                          'updated',
                        ].includes(props.row.statusCode)
                      "
                      variant="warning"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['rejected', 'unpublish'].includes(props.row.statusCode)
                      "
                      variant="danger"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['approved', 'publish', 'publish_draft'].includes(
                          props.row.statusCode
                        )
                      "
                      variant="success"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge v-else>-</b-badge>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
